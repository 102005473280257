/* @import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400&family=Fauna+One&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@300;400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.app {
  margin: auto;
}

.home {
  margin-top: 110px;
}

.info {
  margin-top: 150px;
}

.info-option {
  color: var(--ORANGE, #F1633F);
  font-family: Lato;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  /* 157.143% */
}

/* Style The Dropdown Button */
.dropbtn {
  background-color: transparent;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content .menu-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content .menu-item:hover {
  background-color: #f1f1f1
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: transparent;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.magnifier-content {
  display: flex;
}

.magnifier-content>div {
  flex: 1;
}

.magnifier-zoom {
  position: relative;
}

.magnifier-zoom div {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.magnifier-zoom div:first-child {
  opacity: 1;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.accordion:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

.materials ul {
  display: inline-block;
  text-align: left;
  padding-left: 0;
}

.materials li {
  display: flex;
  align-items: center;
}

.materials li::before {
  content: 'content_cut';
  font-family: 'Material Icons';
}

/* .materials li::marker {
  content: 'content_cut';
  font-family: 'Material Icons';
} */

.materials li>span {
  display: inline-block;
  vertical-align: middle;
}

.thumbs {
  display: flex;
  justify-items: flex-start;
  margin-left: -40px;
}

.MuiInputLabel-root {
  color: var(--ORANGE, #F1633F) !important;
}

.size-chart {
  text-align: center;
  padding: 50px 10% 0;
  font-family: 'Cormorant Infant';
  font-size: medium;
}

.size-chart thead tr {
  background: #0E2A22;
  color: #F8F8F8;
}

.size-chart tr td {
  border-bottom: 1px solid #eee;
}